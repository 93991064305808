import React, { useEffect, useState } from 'react';

interface MidiListenerProps {
    onMidiMessage: (message: WebMidi.MIDIMessageEvent, timestamp: number) => void;
}

const MidiListener: React.FC<MidiListenerProps> = ({ onMidiMessage }) => {
    const [midiAccess, setMidiAccess] = useState<WebMidi.MIDIAccess | null>(null);

    useEffect(() => {
        const initializeMidi = async () => {
            try {
                const access = await navigator.requestMIDIAccess({
                    sysex: false,
                    software: false
                });
                console.log('MIDI access:', access);
                setMidiAccess(access);

                access.onstatechange = (event: WebMidi.MIDIConnectionEvent) => {
                    console.log('MIDI state change:', event);
                };

                for (const input of access.inputs.values()) {
                    input.onmidimessage = (event: WebMidi.MIDIMessageEvent) => {
                        const timestamp = performance.now();
                        onMidiMessage(event, timestamp);
                    };
                }
            } catch (error) {
                console.error('Failed to get MIDI access:', error);
            }
        };

        initializeMidi();

        return () => {
            if (midiAccess) {
                for (const input of midiAccess.inputs.values()) {
                    input.onmidimessage = null;
                }
            }
        };
    }, [onMidiMessage]);

    useEffect(() => {
        if (midiAccess) {
            const handleDeviceConnection = (event: WebMidi.MIDIConnectionEvent) => {
                if (event.port.type === 'input') {
                    if (event.port.state === 'connected') {
                        console.log('MIDI device connected:', event.port.name);
                        (event.port as WebMidi.MIDIInput).onmidimessage = (midiEvent: WebMidi.MIDIMessageEvent) => {
                            const timestamp = performance.now();
                            onMidiMessage(midiEvent, timestamp);
                        };
                    } else if (event.port.state === 'disconnected') {
                        console.log('MIDI device disconnected:', event.port.name);
                        (event.port as WebMidi.MIDIInput).onmidimessage = null;
                    }
                }
            };

            midiAccess.onstatechange = handleDeviceConnection;

            return () => {
                midiAccess.onstatechange = null;
            };
        }
    }, [midiAccess, onMidiMessage]);

    return null;
};

export default MidiListener;